.jaweb-chatbot .scroll-wrapper {
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  -ms-overflow-style: auto;
}

.jaweb-chatbot .scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.jaweb-chatbot .scroll-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.jaweb-chatbot .flex-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}

.jaweb-chatbot .bouncing-loader {
  display: flex;
  justify-content: center;
  padding-block: 5px;
}

.jaweb-chatbot .bouncing-loader > div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 1;
  border:2px solid rgb(127, 40, 248); 
  background-color: transparent;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.jaweb-chatbot .bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.jaweb-chatbot .bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@media (max-width: 768px) {
  .jaweb-chatbot .scroll-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .jaweb-chatbot .flex-row {
    justify-content: flex-start;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}






.fixed {
  position: fixed;
}

.bottom-4 {
  bottom: 1rem;
}

.right-4 {
  right: 1rem;
}

.p-4 {
  padding: 1rem;
}

.bg-white {
  background-color: white;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-lg {
  font-size: 1.125rem;
}

.font-semibold {
  font-weight: 600;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.text-white {
  color: white;
}

.rounded {
  border-radius: 0.25rem;
}

.hover\:bg-blue-600:hover {
  background-color: #3182ce;
}

.transition-colors {
  transition: background-color 0.2s;
}