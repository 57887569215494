
  @tailwind base;
  @tailwind components;
  @tailwind utilities;


.jaweb-chatbot .typing-indicator-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border:2px solid rgb(127, 40, 248);
  border-radius: 50%;
  margin: 0 2px;
  animation: typing-indicator 1s infinite;
}

@keyframes typing-indicator {
  0% {
    opacity: 0.5;
    transform: scale(0.7);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.5;
    transform: scale(0.7);
  }
}


.waveform {
  display: flex;
  gap: 4px;
}

.waveform span {
  display: block;
  width: 4px;
  height: 8px;
  background: #4a90e2;
  animation: wave 1s infinite ease-in-out alternate;
}

.waveform span:nth-child(1) {
  animation-delay: 0s;
}

.waveform span:nth-child(2) {
  animation-delay: 0.2s;
}

.waveform span:nth-child(3) {
  animation-delay: 0.4s;
}

.waveform span:nth-child(4) {
  animation-delay: 0.6s;
}

.waveform span:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes wave {
  0% {
    height: 6px;
  }
  100% {
    height: 16px;
  }
}
